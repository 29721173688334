// See here:
// https://reactjs.org/blog/2017/07/26/error-handling-in-react-16.html
import React from 'react';
import PropTypes from 'prop-types';

import { phoneNumber } from '../config';
import { logError } from '../utils/errors';

class ErrorBoundary extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error, errorInfo) {
    logError(error, errorInfo, true);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ maxWidth: 800, margin: '0 auto', padding: 40 }}>
          <div style={{ textAlign: 'center' }}>
            <img
              src="https://res.cloudinary.com/coastline-academy/image/upload/c_scale,q_auto,w_400/v1587161099/misc-images/404-towing_ffvyn0.png"
              alt="Coastline 404 Page"
            />
          </div>
          <h2>Oops! Something went wrong.</h2>
          <p>
            There seems to be an issue with
            our website right now. This can usually be fixed by refreshing your
            browser, or go to <a href="/">our homepage</a> and try again.
          </p>
          <p>
            If that doesn&apos;t work, please give us a call at {phoneNumber} and
            we&apos;ll try to help you out right away.
          </p>
          <p>
            <em>Note: Coastline has been alerted to this issue.</em>
          </p>
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;

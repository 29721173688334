import React, { Suspense, lazy } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import {
  ThemeProvider, StyledEngineProvider, createTheme,
} from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import CssBaseline from '@mui/material/CssBaseline';

import { page } from '../utils/analytics';
import '../index.css';
import ErrorBoundary from '../components/ErrorBoundary';
import Loading from '../components/template-parts/Loading';
import ChatBubbleFab from '../components/template-parts/ChatBubbleFab';
import Reload from './Reload';

const AsyncMainApp = lazy(() => import('./DynamicApp'));
const AsyncStaticApp = lazy(() => import('./StaticApp'));

declare module '@mui/material/styles' {
  interface Palette {
    accentOne: Palette['primary'];
    accentTwo: Palette['primary'];
    accentThree: Palette['primary'];
  }
  interface PaletteOptions {
    accentOne: PaletteOptions['primary'];
    accentTwo: PaletteOptions['primary'];
    accentThree: PaletteOptions['primary'];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#1E2631',
    },
    secondary: {
      main: '#0BD1C2',
    },
    accentOne: {
      main: '#FF70A6',
    },
    accentTwo: {
      main: '#9EF62E',
    },
    accentThree: {
      main: '#E00056',
    },
    text: {
      primary: '#222222',
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#0BD1C2',
            color: '#1E2631',
          },
        },
      },
    },
  },
});

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

// This is called by index.js and decides whether to load our entire app
// or just the landing page stuff, depending on the route.

const MainRouter = () => {
  const [prevPath, setPrevPath] = React.useState('');
  const location = useLocation();

  React.useEffect(() => {
    const nextPage = `${location.pathname}${location.search}`;
    if (prevPath !== nextPage) {
      page(nextPage);
      setPrevPath(nextPage);
    }
  }, [location.pathname, location.search, prevPath]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorBoundary>
          <>
            <Helmet
              titleTemplate="%s | Driving Lessons & Drivers Ed by Coastline"
            >
              <title>The Modern Driving School</title>
              <meta name="description" content="Coastline Academy is the modern driving school. Book a driving lesson in your city today." />
            </Helmet>

            <Suspense fallback={<Loading />}>
              <Route>
                <ScrollToTop />
              </Route>
              <Switch>
                {/* Priority main app routes. */}
                <Route exact path="/future/admin">
                  <Reload />
                </Route>
                <Route exact path="/profile/courses">
                  <AsyncMainApp />
                </Route>
                <Route path="/prep">
                  <AsyncMainApp />
                </Route>
                <Route path="/articles">
                  <AsyncMainApp />
                </Route>
                <Route exact path="/il/course/drivers-ed">
                  <AsyncMainApp />
                </Route>
                <Route exact path="/ca/course/classroom-drivers-ed">
                  <AsyncMainApp />
                </Route>
                <Route exact path="/:state/course/:courseType">
                  <AsyncMainApp />
                </Route>
                {/* Landing page routes. */}
                <Route exact path="/">
                  <AsyncStaticApp />
                </Route>
                <Route exact path="/location/amador-valley-driving-school">
                  <AsyncStaticApp />
                </Route>
                <Route exact path="/:state/location/:id/:type">
                  <AsyncStaticApp />
                </Route>
                <Route exact path="/location/:id/:type">
                  <AsyncStaticApp />
                </Route>
                <Route exact path="/:state/location/:id">
                  <AsyncStaticApp />
                </Route>
                <Route exact path="/location/:id">
                  <AsyncStaticApp />
                </Route>
                <Route exact path="/state/:id">
                  <AsyncStaticApp />
                </Route>
                <Route exact path="/dmv-handbook">
                  <AsyncStaticApp />
                </Route>
                <Route exact path="/book">
                  <AsyncStaticApp />
                </Route>
                <Route exact path="/partner/book">
                  <AsyncStaticApp />
                </Route>
                <Route exact path="/dmv-practice-test">
                  <AsyncStaticApp />
                </Route>
                <Route exact path="/coastline-academy-driving-school-reviews">
                  <AsyncStaticApp />
                </Route>
                <Route exact path="/coastline-yelp-reviews">
                  <AsyncStaticApp />
                </Route>
                <Route exact path="/drivers-ed">
                  <AsyncStaticApp />
                </Route>
                <Route exact path="/ca/drivers-ed/land">
                  <AsyncStaticApp />
                </Route>
                <Route exact path="/:state/drivers-ed">
                  <AsyncStaticApp />
                </Route>
                <Route exact path="/courses">
                  <AsyncStaticApp />
                </Route>
                <Route exact path="/:state/courses">
                  <AsyncStaticApp />
                </Route>
                <Route exact path="/texas">
                  <Redirect to="/state/tx" />
                </Route>
                <Route exact path="/affiliate-signup">
                  <AsyncStaticApp />
                </Route>
                <Route exact path="/hs/:state/:highschool">
                  <AsyncStaticApp />
                </Route>
                <Route exact path="/learn">
                  <AsyncStaticApp />
                </Route>
                <Route exact path="/lesson-partner/:partner/:state">
                  <AsyncStaticApp />
                </Route>
                {/* This will later route to partner-specific landing pages */}
                <Route exact path="/partner/:partner">
                  <AsyncStaticApp />
                </Route>
                {/* Main app routes. */}
                <Route path="*">
                  <AsyncMainApp />
                </Route>
              </Switch>
            </Suspense>
            <ChatBubbleFab />
          </>
        </ErrorBoundary>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default MainRouter;

import React from 'react';
import { useLocation } from 'react-router-dom';
import { ChatBubble } from '@mui/icons-material';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab,
} from '@mui/material';
import { officeHours, phoneNumber } from '../../config';

const ChatBubbleFab = () => {
  const [doShowBubble, setDoShowBubble] = React.useState(true);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const location = useLocation();

  const handleDialogclose = () => {
    setIsDialogOpen(false);
  };

  React.useEffect(() => {
    setDoShowBubble(!(location.pathname.includes('/admin')));
  }, [location.pathname]);

  if (!doShowBubble) return null;

  return (
    <>
      <Fab
        color="secondary"
        style={{ position: 'fixed', bottom: 40, right: 40 }}
        onClick={(() => setIsDialogOpen(true))}
        data-cy="chat-bubble-fab"
        aria-label="Chat with us"
      >
        <ChatBubble style={{ color: '#ffffff' }} />
      </Fab>

      <Dialog
        open={isDialogOpen}
        onClose={handleDialogclose}
      >
        <DialogTitle>Text Us</DialogTitle>
        <DialogContent>
          Text us at <strong><a href={`sms:${phoneNumber}`}>{phoneNumber}</a></strong> and
          we&apos;ll get back to you right away. Our typical support
          hours are {officeHours}.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogclose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChatBubbleFab;

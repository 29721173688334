import { useEffect } from 'react';

// Used as a redirect to the parent Next.js app
const Reload = () => {
  useEffect(() => {
    // Does nothing if running directly from legacy
    if (window.location.port === '3000') {
      return;
    }
    window.location.reload();
  }, []);

  return null;
};

export default Reload;

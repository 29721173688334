import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const Loading = () => (
  <div
    style={{
      width: '100%',
      textAlign: 'center',
    }}
    data-cy="loading-indicator"
  >
    <CircularProgress />
  </div>
);

export default Loading;
